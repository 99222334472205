"use client";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
// components/Header.tsx
import React, { useState } from "react";
import ProfileNavigation from "./ProfileNavigation";
import { routeUrls } from "../dataMockups/constants";
import { useUser } from "@clerk/nextjs";
import { getInitials } from "../utils/GenericFunction";
import MedEngineIcon from "../../public/imgs/icons/icon.png";

const Header = () => {
	const pathname = usePathname();
	const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
	const { isSignedIn, isLoaded, user } = useUser();

	// checker of the urls
	const isHomePage = pathname === "/";
	const isRegistrationFlowPage =
		pathname === "/auth/login" || pathname === "/auth/registration" || pathname === "/preference";
	const isSearchPage = pathname.startsWith("/search");
	const isFeedPage = pathname.startsWith("/feed");
	// end checker of the urls

	return (
		<>
			<header
				className={`${isHomePage ? "absolute w-full bg-transparent" : "homeGradientBg sticky top-0 z-50"} flex items-center justify-between p-4`}
			>
				<Link href={"/"} className="flex items-center gap-2">
					<Image src={MedEngineIcon} width={35} height={35} alt="Logo" />
					{!isHomePage && (
						<span className="hidden text-xl font-medium text-white lg:block">
							<span className="font-bold">Med</span>Engine.ai
						</span>
					)}
				</Link>
				{!isRegistrationFlowPage && (
					<nav className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-fit w-fit space-x-4">
						<Link
							href="/search/query:none/author:none/journal:none/startdate:2000/enddate:2024/searchEngine:MedEngine/Clinical%20Trial:true/Meta-Analysis:true/Randomized%20Controlled%20Trial:true/Review:true/Case%20Report:true/Primary%20Article:true/All:true"
							className={`border-b-2 text-primaryWhite ${isSearchPage ? "border-b-primaryWhite" : "border-b-transparent"}`}
						>
							Search
						</Link>
						<Link
							href="/feed/author:none/journal:none/startdate:2000/enddate:2024/searchEngine:MedEngine/Clinical%20Trial:true/Meta-Analysis:true/Randomized%20Controlled%20Trial:true/Review:true/Case%20Report:true/Primary%20Article:true/All:true/isTop50Journal:true/hasPdf:true"
							className={`border-b-2 text-primaryWhite ${isFeedPage ? "border-b-primaryWhite" : "border-b-transparent"}`}
						>
							Feed
						</Link>
					</nav>
				)}
				{isSignedIn ? (
					<>
						<button
							onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
							className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-400 text-white lg:hidden"
						>
							{isLoaded && getInitials(user?.fullName)}
						</button>
						<Link
							href={routeUrls.profile}
							className="hidden h-8 w-8 items-center justify-center rounded-full bg-gray-400 text-white lg:flex"
						>
							{isLoaded && getInitials(user?.fullName)}
						</Link>
					</>
				) : (
					!isRegistrationFlowPage && (
						<Link href="/auth/login">
							<button
								type="button"
								// onClick={() => setShow(!show)}
								className="h-[40px] w-fit min-w-[78px] rounded border border-primaryWhite px-[16px] text-sm font-medium text-primaryWhite"
							>
								Sign In
							</button>
						</Link>
					)
				)}
			</header>
			{isMobileMenuOpen && <ProfileNavigation setShowProfile={setMobileMenuOpen} showProfileNav={isMobileMenuOpen} />}
		</>
	);
};

export default Header;
