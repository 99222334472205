import { usePathname } from "next/navigation";
import Link from "next/link";

import { linkInterface } from "../interfaces/interfaces";
import Image from "next/image";
import { profileUrls } from "../dataMockups/constants";

interface ProfileNavigationInterface {
	setShowProfile: (value: boolean) => void;
	showProfileNav: boolean;
}

function ProfileNavigation({ setShowProfile, showProfileNav }: ProfileNavigationInterface) {
	const pathname = usePathname();

	return (
		<div className="fixed bottom-0 left-0 right-0 z-50 mx-auto flex h-fit w-full max-w-[375px] flex-col bg-ThirdWhite lg:hidden">
			<div className="flex h-[64px] w-full flex-row items-center justify-between border-b px-6 py-3">
				<h6 className="text-xl font-semibold text-primaryBlack">Profile</h6>
				<button
					onClick={() => {
						setShowProfile(false);
					}}
				>
					<Image width={15} height={15} alt="close icon" src={`/imgs/icons/cancel.svg`} />
				</button>
			</div>
			{profileUrls.map((link: linkInterface, index: number) => (
				<Link
					key={index}
					href={link.url}
					className={` ${pathname === link.url ? "bg-primaryBlue/5" : "bg-transparent"} flex h-[48px] w-full items-center px-6 py-3 font-medium text-primaryBlack duration-500 hover:bg-primaryBlue/5`}
				>
					{link.title}
				</Link>
			))}
		</div>
	);
}

export default ProfileNavigation;

// {
// 	profileUrls.map((link: linkInterface, index: number) => (
// 		<Link
// 			key={index}
// 			href={link.url}
// 			className={` ${pathname === link.url ? "bg-primaryBlue/5" : "bg-transparent"} flex items-center text-primaryBlack font-medium h-[48px] w-full py-3 px-6 hover:bg-primaryBlue/5 duration-500 `}
// 		>
// 			{link.title}
// 		</Link>
// 	));
// }
