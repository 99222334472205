import { SortState } from "src/interfaces/interfaces";

export const basePathUrl = process.env.NEXT_PUBLIC_API_URL;
export const baseUrl = `${basePathUrl}/api/`;

export const routeUrls = {
	bookmarks: "/bookmarks",
	folders: "/folders",
	home: "/",
	search: "/",
	profile: "/profile/personal-information",
	feed: "/feed",
	foldersPage: "/folders",
	login: "/auth/login",
};

export const profileUrls = [
	{ title: "Personal Information", url: "/profile/personal-information" },
	{ title: "Preferences", url: "/profile/prefrences" },
	// { title: "Settings", url: "/profile/settings" },
	{ title: "Folders", url: "/folders" },
	{ title: "Feedback", url: "/profile/feedback" },
];

export const serverUrls = {
	search: "search",
	article: "article?articlePmid",
	deleteFolderArticle: "folders/articles?folderId",
	articleSimpleView: "article/generateSimpleView?articlePmid",
	generateTranslation: "article/generateTranslation?",
	bookmarks: "bookmarks",
	userPrefrence: "preferences?user_id",
	prefrences: "prefrences",
	feedback: "feedback",
	bookmarksarticles: "bookmarks-articles",
	folders: "folder-server",
	nbib: "article/download_nbib",
	feed: "feed",
	citationsAndReferences: "article/citationsAndReferences?pmid",
	relatedArticles: "article/similar?pmid",
	authorAutoSuggest: "search/autocomplete/author?author_name=",
	journalAutoSuggest: "search/autocomplete/journal?journal_name=",
};

export const initialArticleTypes = {
	"Clinical Trial": false,
	"Meta-Analysis": false,
	"Randomized Controlled Trial": false,
	Review: false,
	"Case Report": false,
	"Primary Article": false,
	All: false,
};

export const articleTypesConstants = [
	"Clinical Trial",
	"Meta-Analysis",
	"Randomized Controlled Trial",
	"Review",
	"Case Report",
	"Primary Article",
	"All",
];

export const initialSortState: SortState = {
	author: "",
	journal: "",
	startdate: "",
	enddate: "",
	articleTypes: {
		"clinical Trial": false,
		"meta-Analysis": false,
		"randomized Controlled Trial": false,
		review: false,
		"Case Report": false,
		"Primary Article": false,
		All: false,
	},
	other: {
		"free Pdf Available": false,

		"top 50 Percent Journal Ranking": false,
	},
};

export const initialSearchState = {
	query: "",
	author: "",
	journal: "",
	startdate: "",
	enddate: "",
	searchEngine: "MedEngine",
	"Clinical Trial": true,
	"Meta-Analysis": true,
	"Randomized Controlled Trial": true,
	Review: true,
	"Case Report": true,
	"Primary Article": true,
	All: true,
};
